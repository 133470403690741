import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { ProductItem } from "./ProductItem";
import { Divider } from "@material-ui/core";

export const AllProducts = ({ products, state }) => {
  return (
    <>
      {products.map((item) => {
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                minHeight: "36px",
                margin: 0,
                backgroundColor: "#E6E6E6",
                border: "1px solid #0000000F",
                ".MuiAccordionSummary-root": {
                  margin: 0,
                },
              }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                {" "}
                {item.name}
              </Typography>
            </AccordionSummary>
            {item.products.map((prod) => {
              return (
                <>
                  <ProductItem product={prod} state={state} />
                </>
              );
            })}
          </Accordion>
        );
      })}
    </>
  );
};

import { Box } from "@material-ui/core";
import { Typography } from "@mui/material";
import React from "react";

export const ImageView = ({ product }) => {
  return (
    <>
      <Box sx={{ width: "100%", height: "50%" }}>
        <Box
          component="img"
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
          src={product.image}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
          gap: "6px",
        }}
      >
        <Typography
          sx={{
            width: "4rem",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "13px",
            fontWeight: "400",
          }}
        >
          {product.name}
        </Typography>
        <Box sx={{ color: "#1560D4", fontSize: "13px", fontWeight: "600" }}>
          ${Number(product.price).toFixed(2)}
        </Box>
      </Box>
    </>
  );
};

import { Stack } from "@mui/material";
import React from "react";
import InputControl from "../../../shared/components/InputControl";
import { Box } from "@material-ui/core";

export const AddCategory = ({
  selectedCategory,
  categories,
  onCategoryNameChange,
  onDeleteCategory,
}) => {
  return (
    <Stack
      direction="row"
      sx={{ padding: "12px", justifyContent: "space-between" }}
    >
      <Box sx={{ width: "390px" }}>
        <InputControl
          label="Category Name"
          fullWidth
          value={categories[selectedCategory].name}
          //   name="name"
          onChange={(e) =>
            onCategoryNameChange(selectedCategory, e.target.value)
          }
          //   error={validator.current.message("Template Name", name, "required")}
          //   helperText={validator.current.message(
          //     "Template Name",
          //     name,
          //     "required"
          //   )}
        />
      </Box>
      <Box
        sx={{
          width: "40px",
          height: "40px",
          backgroundColor: "#F5DDDA",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: categories.length > 1 ? "pointer" : "not-allowed",
          "&:hover": {
            backgroundColor: "rgb(245, 226, 218)",
          },
        }}
        onClick={() =>
          categories.length > 1 && onDeleteCategory(selectedCategory)
        }
      >
        <img alt="delete" src="/icons/delete_danger.png" />
      </Box>
    </Stack>
  );
};

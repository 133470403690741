import { AccordionDetails, Box, Divider, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useDrag } from "react-dnd";

export const ProductItem = ({ product, state }) => {
  const isProductPresent = useMemo(
    () =>
      state.data.categories[state.selectedCategory].items.find(
        (i) => i.id === product.id
      ),
    [state, product.id]
  );
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "prd",
      item: { productId: product.id, type: "garbage", product: product },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: () => {
        if (isProductPresent) {
          return false;
        }
        return true;
      },
    }),
    [isProductPresent, product]
  );

  return (
    <Box
      ref={drag}
      sx={{
        cursor: !!isProductPresent ? "not-allowed" : "grabbing",
      }}
    >
      <Divider />
      <AccordionDetails sx={{ padding: "5px 25px", minHeight: "49px" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: !!isProductPresent ? "rgba(0, 0, 0, 0.1)" : "",
          }}
        >
          {product.name}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            color: !!isProductPresent ? "rgba(0, 0, 0, 0.1)" : "#666666",
          }}
        >
          {product?.price ? `$${Number(product.price).toFixed(2)}` : ""}
        </Typography>
      </AccordionDetails>
    </Box>
  );
};

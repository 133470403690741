import {
  AddCategory,
  Categories,
  CategoryGrid,
  Header,
  ProductList,
} from "../components";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSalesLayout } from "../hooks/useSalesLayout";
import { useParams } from "react-router-dom";
import { CustomeDialog, Loader } from "../../../shared";
import SimpleReactValidator from "simple-react-validator";
import { useState } from "react";
import { useRef } from "react";
import { Divider } from "@material-ui/core";

export const AddOrEditLayout = () => {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { id } = useParams();
  const {
    state,
    changeCategory,
    moveItemToGarbage,
    addColumns,
    moveItemToGrid,
    onChange,
    onChangeColor,
    onSaveGrid,
    onUpdateGrid,
    onAddNewCategory,
    onCategoryNameChange,
    onDeleteCategory,
    onConfirmDeleteCategory,
    moveCategory,
  } = useSalesLayout({ id });
  const { isBusy, isLoading, selectedCategory } = state;
  if (isBusy || isLoading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Container maxWidth={false} disableGutters>
        <Header
          state={state}
          onChange={onChange}
          onSaveGrid={onSaveGrid}
          onUpdateGrid={onUpdateGrid}
          id={id}
          validator={validator}
          forceUpdate={forceUpdate}
        />

        <DndProvider backend={HTML5Backend}>
          <Grid container>
            <Grid item xs={9}>
              <Categories
                data={state.data.categories}
                selectedCategory={state.selectedCategory}
                changeCategory={changeCategory}
                onAddNewCategory={onAddNewCategory}
                moveCategory={moveCategory}
              />
              <AddCategory
                selectedCategory={state.selectedCategory}
                categories={state.data.categories}
                onCategoryNameChange={onCategoryNameChange}
                onDeleteCategory={onDeleteCategory}
              />
              <Divider />
              <CategoryGrid
                state={state}
                addColumns={addColumns}
                moveProduct={moveItemToGrid}
                onChangeColor={onChangeColor}
                onAddNewCategory={onAddNewCategory}
              />
            </Grid>
            <Grid item xs={3}>
              <ProductList
                moveItemToGarbage={moveItemToGarbage}
                state={state}
              />
            </Grid>
          </Grid>
        </DndProvider>
      </Container>
      <CustomeDialog
        title={
          <Typography variant="h6" sx={{ padding: "20px" }}>
            Delete Group
          </Typography>
        }
        PaperProps={{
          sx: {
            minWidth: "423px",
            minHeight: "190px",
          },
        }}
        open={state.data.isDeleteGroupOpen}
        handleClose={onDeleteCategory}
        content={
          <div style={{ padding: "20px" }}>
            <Stack gap="12px">
              <Typography>
                Are you sure you want to Delete{" "}
                {state.data.isDeleteGroupOpen &&
                  state.data.categories[selectedCategory].name}{" "}
                from Groups?
              </Typography>
            </Stack>

            <Stack
              direction="row"
              gap="12px"
              marginTop={3}
              justifyContent="flex-end"
            >
              <Button onClick={onDeleteCategory}>No</Button>
              <Button onClick={onConfirmDeleteCategory} variant="contained">
                Yes
              </Button>
            </Stack>
          </div>
        }
      />
    </>
  );
};

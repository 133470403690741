import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import qs from "qs";
import { usePermission, useTabs } from "../../../shared";
import { editStoreConnectionApi, editStoreStatus, editStoreStatusApi, getOnlineOrdering, getIntegrationListApi } from "../api/onlineOrderingApi";
import { AppContext } from "../../../store";
import { useMenuTemplate } from "../../MenuTemplate";
import { createTemplate, showChannel } from "../../Menulog/api/menuLogApi";

export const useOnlineOrderingList = ({ isList }) => {

  const onlineOrderingID = 101
  const isEditable = usePermission("online-ordering-modify");
  const { appState } = useContext(AppContext);
  const { onlineOrdering: currentTab } = appState.currentTabs;
  const { setCurentTab } = useTabs();
  const [state, setState] = useImmer({
    isBusy: false,
    initialLoad: true,
    isSaveButtonBusy: false,
    onlineOrderingList: [],
    dataMenu: {
      channel_id: 101,
      menu_id: "",
    },
    isOpenConnect: false,
    isModalType: "",
    selectedStore: null,
  });
  const { state: stateMenuTempalte } = useMenuTemplate({ isList: false });
  const optionsMenuTemplate = stateMenuTempalte?.menuTemplates?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  useEffect(() => {
    if (isList) {
      getOnlineOrderingList();
    }
  }, [isList]);

  const getOnlineOrderingList = async (searchText) => {
    let params = {};
    if (searchText) {
      params = { ...params, search: searchText };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };

    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });

      const res = await getIntegrationListApi(onlineOrderingID, query);

      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.initialLoad = false;
          draft.onlineOrderingList = res.data;
        });
      }
    } catch (err) {
      console.log(err);

      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };
  const handleStoreActive = async (value, e, type) => {
    let payload = {
      connection_status: e ? 1 : 0,
    };
    try {
      const res = await editStoreConnectionApi(onlineOrderingID, value?.store_id, payload);
      if (res.success) {
        successMessage(res.message);
        handleModal("connect", false, null)
        getOnlineOrderingList();
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleStoreStatus = async (value, e, type) => {
    let payload = {
      status: e ? 1 : 0,
    };
    try {
      const res = await editStoreStatusApi(onlineOrderingID, value?.store_id, payload);
      if (res.success) {
        successMessage(res.message);
        handleModal("connect", false, null)
        getOnlineOrderingList();
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.dataMenu[name] = value;
    });
  };
  const handleSubmit = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createTemplate(state.dataMenu);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getTemplatesDetails = async (channel_id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await showChannel(channel_id);
      if (res.data) {
        setState((draft) => {
          draft.dataMenu.menu_id = res?.data?.id;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };


  const handleModal = (type, state, data) => {
    console.log(type, state, data);

    switch (type) {
      case "connect":
        setState((draft) => {
          draft.isOpenConnect = state;
          draft.selectedStore = data;
          draft.isModalType = type;
        });
        break;
      case "store-open":
        setState((draft) => {
          draft.isOpenConnect = state;
          draft.selectedStore = data;
          draft.isModalType = type;
        });
        break;
    }
  };


  return {
    state,
    currentTab,
    setCurentTab,
    handleStoreActive,
    optionsMenuTemplate,
    handleInputChange,
    handleSubmit,
    isEditable,
    handleModal,
    handleStoreStatus,
  };
};

import React, { useCallback } from "react";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { createGrid, getAllProducts, getDetails, updateGrid } from "../api";
import { useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../../utils";

export const useSalesLayout = ({ id }) => {
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isBusy: false,
    isLoading: false,
    isSaveButtonBusy: false,
    selectedCategory: 0,
    isDeleteGroupOpen: false,
    allProducts: [],
    data: {
      name: "",
      grid_view: "image",
      categories: [
        {
          id: null,
          name: "untitled",
          no_of_grids: 24,
          items: [],
        },
      ],
    },
  });

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (id) {
      getTemplateDetails(id);
    }
  }, [id]);

  const getTemplateDetails = async (id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getDetails(id);
      const categories = res.data.categories.map((category) => {
        let greatesIndex = 0;
        category.items.forEach((prd) => {
          const x = Number(prd.position[0]);
          const y = Number(prd.position[1]);
          if (x * 8 + y > greatesIndex) {
            greatesIndex = x * 8 + y;
          }
        });
        return {
          ...category,
          items: category.items.map((i) => ({
            ...i,
            id: i.product_id,
            type: "product",
          })),
          no_of_grids: greatesIndex + 2,
        };
      });

      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.data = { ...res.data, categories };
        });
      }
    } catch (err) {
      console.log("err", err);
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const getProducts = async () => {
    try {
      setState((draft) => {
        draft.isLoading = true;
      });
      const res = await getAllProducts();
      const allProducts = res.data.map((category) => {
        return {
          ...category,
          products:
            category.products.length > 0
              ? category.products.map((item) => ({
                  id: item.id,
                  name: item.name,
                  grid_color: null,
                  image: item.image,
                  price: item.price,
                  type: "product",
                  position: [0, 0],
                  alias: item.alias,
                  colour: item.colour,
                  font_color: "#FFFFFF",
                }))
              : [],
        };
      });
      if (res.success) {
        setState((draft) => {
          draft.isLoading = false;
          draft.allProducts = allProducts;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const onUpdateGrid = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const hasEmptyCategory = state.data.categories.find((item) => !item.name);
      if (hasEmptyCategory) {
        errorMessage("category name should not be empty");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      } else {
        const { name, grid_view, categories } = state.data;

        const categoriesData = categories.map((category, index) => {
          return {
            ...category,
            items: category.items.map((product) => {
              return {
                product_id: product.id,
                position: product.position,
                color: product.grid_color,
                font_color: product.font_color ?? "#FFFFFF",
              };
            }),
          };
        });
        const payload = {
          name,
          grid_view,
          categories: categoriesData,
          status: 1,
        };
        const res = await updateGrid(payload, id);
        if (res.success) {
          successMessage("Template updated Successfully");
          navigate(`/keypad`);
          setState((draft) => {
            draft.isSaveButtonBusy = false;
          });
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onSaveGrid = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const hasEmptyCategory = state.data.categories.find((item) => !item.name);

      if (hasEmptyCategory) {
        errorMessage("category name should not be empty");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      } else {
        const { name, grid_view, categories } = state.data;
        const categoriesData = categories.map((category, index) => {
          return {
            ...category,
            items: category.items.map((product) => {
              return {
                product_id: product.id,
                position: product.position,
                color: product.grid_color,
                font_color: product.font_color ?? "#FFFFFF",
              };
            }),
          };
        });
        const payload = {
          name,
          grid_view,
          categories: categoriesData,
          status: 1,
        };
        const res = await createGrid(payload);
        if (res.success) {
          successMessage("Template created Successfully");
          navigate(`/keypad`);
          setState((draft) => {
            draft.isSaveButtonBusy = false;
          });
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const changeCategory = (index) => {
    setState((draft) => {
      draft.selectedCategory = index;
    });
  };

  const addColumns = () => {
    setState((draft) => {
      draft.data.categories[draft.selectedCategory].no_of_grids =
        draft.data.categories[draft.selectedCategory].no_of_grids + 8;
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const onChangeColor = (color, id, type) => {
    const updatedProducts = state.data.categories[
      state.selectedCategory
    ].items.map((item) => {
      if (item.id === id) {
        return { ...item, [type]: color };
      } else {
        return item;
      }
    });
    setState((draft) => {
      draft.data.categories[draft.selectedCategory].items = updatedProducts;
    });
  };

  const moveItemToGarbage = (item) => {
    setState((draft) => {
      const selectedCategory = draft.data.categories[draft.selectedCategory];
      draft.data.categories[draft.selectedCategory].items =
        selectedCategory.items.filter((prd) => prd.id !== item.productId);
    });
  };

  const moveItemToGrid = (x, y, item, prds) => {
    if (item.type === "garbage") {
      const updatedItem = item.product;
      setState((draft) => {
        draft.data.categories[draft.selectedCategory].items = [
          ...draft.data.categories[draft.selectedCategory].items,
          {
            ...updatedItem,
            position: [x, y],
          },
        ];
      });
    } else {
      const updatedProducts = prds.map((prd) => {
        if (prd.id === item.productId) {
          return { ...prd, position: [x, y] };
        } else {
          return prd;
        }
      });
      setState((draft) => {
        draft.data.categories[draft.selectedCategory].items = updatedProducts;
      });
    }
  };

  const onAddNewCategory = () => {
    const newCategory = {
      id: null,
      name: "Untitiled",
      no_of_grids: 24,
      items: [],
    };
    setState((draft) => {
      draft.data.categories = [...draft.data.categories, newCategory];
      draft.selectedCategory = draft.selectedCategory + 1;
    });
  };
  const onCategoryNameChange = (index, value) => {
    setState((draft) => {
      draft.data.categories[index].name = value;
    });
  };
  const onDeleteCategory = (index) => {
    setState((draft) => {
      draft.data.isDeleteGroupOpen = !draft.data.isDeleteGroupOpen;
    });
  };

  const onConfirmDeleteCategory = () => {
    setState((draft) => {
      draft.selectedCategory = 0;
      draft.data.categories = draft.data.categories.filter(
        (_, i) => i !== state.selectedCategory
      );
    });
    onDeleteCategory();
  };

  const moveCategory = useCallback(
    (dragIndex, hoverIndex) => {
      setState((draft) => {
        const arr = draft.data.categories;

        // Validation for index bounds
        if (
          dragIndex < 0 ||
          dragIndex >= arr.length ||
          hoverIndex < 0 ||
          hoverIndex >= arr.length
        ) {
          throw new Error("Index out of bounds");
        }

        // Move item within the array
        const item = arr.splice(dragIndex, 1)[0];
        arr.splice(hoverIndex, 0, item);
      });
    },
    [setState]
  );

  return {
    state,
    changeCategory,
    moveItemToGarbage,
    addColumns,
    moveItemToGrid,
    onChange,
    onChangeColor,
    onSaveGrid,
    onUpdateGrid,
    onAddNewCategory,
    onCategoryNameChange,
    onDeleteCategory,
    onConfirmDeleteCategory,
    moveCategory,
  };
};

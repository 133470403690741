import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage, infoMessage } from "../../../utils";
import {
  uploadAllMenuDoordash,
  uploadPushMenuDoordash,
} from "../api/doordashApi";
import { usePermission, useTabs } from "../../../shared";
import { AppContext, AuthContext } from "../../../store";
import { useMenuTemplate } from "../../MenuTemplate";
import { createTemplate, showChannel } from "../../Menulog/api/menuLogApi";
import { getStoreDetailsById } from "../../Stores";
import { editStoreConnectionApi, editStoreStatusApi, getIntegrationListApi } from "../../OnlineOrdering/api/onlineOrderingApi";

export const useDoordashList = ({ isList }) => {
  const doorDashID = 104
  const isEditable = usePermission("doordash-modify");
  const { appState } = useContext(AppContext);
  const { auth } = useContext(AuthContext);
  const { doordash: currentTab } = appState.currentTabs;
  const { setCurentTab } = useTabs();
  const { state: stateMenuTempalte } = useMenuTemplate({ isList: false });
  const optionsMenuTemplate = stateMenuTempalte?.menuTemplates?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
  const [state, setState] = useImmer({
    isBusy: false,
    isOpen: false,
    isActOrDeactBusy: null,
    isDialogOpen: false,
    isAllSaveButton: false,
    isSaveButton: false,
    isSaveButtonBusy: false,
    isResetButtonBusy: false,
    isDoordashErrorOpen: false,
    doordashData: [],
    store_id: null,
    id: "",
    data: {
      store_id: 2,
      doordash: 1,
    },
    isErrorOpen: false,
    errorMessages: null,
    dataMenu: {
      channel_id: 104,
      menu_id: "",
    },
    storeDetails: {
      requestor_email: "",
      merchant_decision_maker_email: "",
      // doordash_store_id: "",
      partner_store_id: "",
      partner_store_name: "",
      provider_type: "",
      requestor_first_name: "",
      requestor_last_name: "",
      requestor_email: "",
      address_city: "",
      address_state: "",
      address_zip: "",
      address_line_1: "",
    },
    doordashList: [],
    isOpenConnect: false,
    isModalType: "",
    selectedStore: null,
  });

  const handleAllMenu = async () => {
    try {
      setState((draft) => {
        draft.isAllSaveButton = true;
      });
      const res = await uploadAllMenuDoordash();
      if (res.status === 200) {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        infoMessage(res.data.message);
      } else {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isAllSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };

  const handlePushMenu = async (id, data) => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
        draft.store_id = id;
      });
      const res = await uploadPushMenuDoordash(id);
      infoMessage(res.data.message);
      // getDoordashStores();
      if (res.status === 200) {
        setState((draft) => {
          draft.isSaveButton = false;
        });
      } else {
        setState((draft) => {
          draft.isSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };
  const toggleAction = (type, e, value) => {
    setState((draft) => {
      draft.isOpen = type;
      draft.data.store_id = value?.store_id;
      draft.data.doordash = e ? 1 : 0;
    });
  };


  const isErrorModal = (v, text) => {
    setState((draft) => {
      draft.isErrorOpen = v;
      draft.errorMessages = text;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.dataMenu[name] = value;
    });
  };
  const handleSubmit = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createTemplate(state.dataMenu);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getTemplatesDetails = async (channel_id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await showChannel(channel_id);
      if (res.data) {
        setState((draft) => {
          draft.dataMenu.menu_id = res?.data?.id;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (currentTab === "2") {
      getTemplatesDetails(state?.dataMenu?.channel_id);
    }
  }, [currentTab]);
  const handleSetup = async (type, id) => {
    setState((draft) => {
      draft.id = id;
      draft.isDialogOpen = type;
    });
  };
  const getDoordashStoreDetail = async (id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getStoreDetailsById(id);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.storeDetails = {
            ...res.data,
            partner_store_name: res?.data?.name,
            partner_store_id: res?.data?.id,
            requestor_first_name: auth.data.first_name,
            requestor_last_name: auth.data.last_name,
            address_city: res.data.city.name,
            address_state: res.data.state.name,
            address_zip: res.data.zip_code,
            address_line_1: res.data.address,
          };
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (state.isDialogOpen) {
      getDoordashStoreDetail(state?.id);
    }
  }, [state.isDialogOpen]);

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.storeDetails[name] = value;
    });
  };

  useEffect(() => {
    if (isList) {
      getDoordashList();
    }
  }, [isList]);

  const getDoordashList = async (searchText) => {

    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });

      const res = await getIntegrationListApi(doorDashID);

      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.initialLoad = false;
          draft.doordashList = res.data;
        });
      }
    } catch (err) {
      console.log(err);

      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };

  const handleModal = (type, state, data) => {
    console.log(type, state, data);

    switch (type) {
      case "connect":
        setState((draft) => {
          draft.isOpenConnect = state;
          draft.selectedStore = data;
          draft.isModalType = type;
          draft.isDialogOpen = false;
        });
        break;
      case "connect-id":
        setState((draft) => {
          draft.id = data.store_id;
          draft.isDialogOpen = state;
          draft.selectedStore = data;
        })
        break;
      case "store-open":
        setState((draft) => {
          draft.isOpenConnect = state;
          draft.selectedStore = data;
          draft.isModalType = type;
        });
        break;
    }
  };

  const handleStoreStatus = async (value, e, type) => {
    let payload = {
      status: e ? 1 : 0,
    };
    try {
      const res = await editStoreStatusApi(doorDashID, value?.store_id, payload);
      if (res.success) {
        successMessage(res.message);
        handleModal("connect", false, null)
        getDoordashList();
      } else {
        errorMessage(res.message);
        handleModal("connect", false, null)
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleStoreActive = async (value, e, type) => {
    console.log(value, e);
    let payload
    const {
      requestor_email,
      merchant_decision_maker_email,
      doordash_store_id,
      partner_store_name,
      requestor_first_name,
      requestor_last_name,
      address_city,
      address_state,
      address_zip,
      address_line_1 } = state.storeDetails

    if (e) {
      payload = {
        requestor_email,
        merchant_decision_maker_email,
        doordash_store_id,
        partner_store_name,
        requestor_first_name,
        requestor_last_name,
        address_city,
        address_state,
        address_zip,
        address_line_1,
        connection_status: 1,
      };
    }
    else {
      payload = {
        connection_status: 0,
      };
    }

    try {
      const res = await editStoreConnectionApi(doorDashID, value?.store_id, payload);
      if (res.success) {
        successMessage(res.message);
        handleModal("connect", false, null)
        getDoordashList();
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  return {
    state,
    handleAllMenu,
    toggleAction,
    handlePushMenu,
    onChange,
    isEditable,
    isErrorModal,
    currentTab,
    setCurentTab,
    optionsMenuTemplate,
    handleInputChange,
    handleSubmit,
    handleSetup,
    setState,
    handleModal,
    handleStoreActive,
    handleStoreStatus,
  };
};

import { Box } from "@material-ui/core";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export const CategoryItem = ({
  changeCategory,
  item,
  index,
  selectedCategory,
  moveCategory,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "category",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCategory(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "category",
    item: () => {
      return { id: selectedCategory, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Box
      key={index}
      ref={ref}
      className="category-parent"
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          opacity: opacity,
          minWidth: "auto",
          maxHeight: "50px",
          borderRadius: "30px",
          padding: "13px 12px",
          border: "1px solid #E6E6E6",
          fontSize: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: index === selectedCategory ? "#1560D4" : "",
          color: index === selectedCategory ? "#fff" : "",
          whiteSpace: "nowrap",
        }}
        onClick={() => changeCategory(index)}
      >
        {item.name}
      </Box>
      <Box
        className="drag-handle"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "4px",
          width: "16px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          right: "-7px",
        }}
      >
        <Box
          component={"img"}
          src="/icons/drag_indicator.svg"
          height={14}
          width={14}
        />
      </Box>
    </Box>
  );
};

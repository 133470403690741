import React, { forwardRef } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  TextField,
  Autocomplete,
  Switch,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
  RadioGroup,
  Radio,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const InputControl = forwardRef((props, ref) => {
  const {
    onMouseDown,
    onClick,
    showPassword,
    label,
    type,
    value,
    width,
    onClear,
    inputLabel,
    inputType,
    isDisabledKeys = [],
    inputRef,
    isDoller = false,
    labelPlacement = "end",
    ...rest
  } = props;
  let inputElement = null;
  switch (type) {
    case "textarea":
      inputElement = (
        <TextField
          fullWidth
          label={label}
          type={type}
          multiline
          rows={4}
          rowsMax={4}
          value={value}
          {...rest}
          ref={ref}
        />
      );
      break;
    case "time":
      inputElement = (
        <TextField
          className="form-control"
          label={label}
          type={type}
          value={value}
          {...rest}
          ref={ref}
        />
      );
      break;
    case "datePicker":
      inputElement = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label={label}
            value={value}
            {...rest}
            renderInput={(params) => <TextField {...params} {...rest} />}
          />
        </LocalizationProvider>
      );
      break;
    case "timePicker":
      inputElement = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            value={value}
            label={label}
            {...rest}
            ref={ref}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                error={rest.error}
                helperText={rest.helperText}
              />
            )}
          />
        </LocalizationProvider>
      );
      break;
    case "dropdown":
      inputElement = (
        <Autocomplete fullWidth value={value} {...rest} ref={ref} />
      );
      break;
    case "password":
      inputElement = (
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            id="outlined-adornment-password"
            label={label}
            value={value}
            {...rest}
            ref={ref}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onClick}
                  onMouseDown={onMouseDown}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText sx={{ color: "#C60808" }}>
            {rest.helperText}
          </FormHelperText>
        </FormControl>
      );
      break;
    case "outlinedInput":
      inputElement = (
        <FormControl fullWidth variant="outlined">
          {inputLabel && <InputLabel id="plu">{inputLabel}</InputLabel>}
          <OutlinedInput
            type={inputType}
            label={label}
            value={value}
            onWheel={(e) => e.target.blur()}
            {...rest}
            ref={ref}
            fullWidth
          />
          <FormHelperText sx={{ color: "#C60808" }}>
            {rest.helperText ?? ""}
          </FormHelperText>
        </FormControl>
      );
      break;
    case "select":
      inputElement = (
        <FormControl sx={{ width: width || "100%" }} fullWidth>
          <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={value}
            input={<OutlinedInput label={label} />}
            MenuProps={{
              style: {
                maxHeight: 250,
              },
            }}
            {...rest}
          >
            {rest.optionValue.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: "#C60808" }}>
            {rest.helperText}
          </FormHelperText>
        </FormControl>
      );
      break;
    case "switch":
      inputElement = (
        <FormControlLabel
          control={<Switch checked={value} {...rest} />}
          label={label}
          labelPlacement={labelPlacement}
        />
      );
      break;
    case "radio-button":
      inputElement = (
        <FormControlLabel
          control={<Radio checked={value} {...rest} />}
          label={label}
        />
      );
      break;
    case "table-search":
      inputElement = (
        <OutlinedInput
          sx={{
            height: "48px",
          }}
          value={value}
          {...rest}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="large" />
            </InputAdornment>
          }
          endAdornment={
            value &&
            onClear && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={onClear}
                position="end"
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
          fullWidth
        />
      );
      break;
    case "number":
      inputElement = (
        <FormControl variant="outlined" sx={{ width: width || "100%" }}>
          <TextField
            size="medium"
            fullWidth
            type={type}
            label={label}
            value={value}
            onKeyDown={(evt) =>
              (["e", "+"].includes(evt.key) ||
                isDisabledKeys.includes(evt.key)) &&
              evt.preventDefault()
            }
            inputRef={inputRef}
            onWheel={(e) => e.target.blur()}
            InputProps={{
              startAdornment: isDoller ? (
                <InputAdornment className="doller-sign" position="start">
                  $
                </InputAdornment>
              ) : null,
            }}
            {...rest}
            ref={ref}
          />
        </FormControl>
      );
      break;
    case "toggle-switch":
      inputElement = (
        <div className="toggle-switch">
          <input
            type="checkbox"
            className="toggle-switch-checkbox"
            id={rest.id}
            checked={value}
            {...rest}
          />
          <label
            className="toggle-switch-label"
            // tabIndex={disabled ? -1 : 1}
            onKeyDown={(e) => {
              if (e.keyCode !== 32) return;
              e.preventDefault();
              // onChange(!value);
            }}
            htmlFor={rest.id}
          >
            <span
              className={
                "toggle-switch-inner"
              }
              data-yes={"OPEN"}
              data-no={"CLOSE"}
              tabIndex={-1}
            />
            <span
              className={
                "toggle-switch-switch"
              }
              tabIndex={-1}
            />
          </label>
        </div>
      );
      break;
    default:
      inputElement = (
        <FormControl variant="outlined" sx={{ width: width || "100%" }}>
          <TextField
            size="medium"
            fullWidth
            type={type}
            label={label}
            value={value}
            onWheel={(e) => e.target.blur()}
            {...rest}
            ref={ref}
          />
        </FormControl>
      );
      break;
  }

  return (
    <>
      {/* {props.label && (
        <label htmlFor={props.name} className="form-label">
          {props.label}
        </label>
      )} */}

      {inputElement}
    </>
  );
});

export default InputControl;

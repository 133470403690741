import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import {
  Chip,
} from "@mui/material";
import { DoordashGenaralDetails } from "../components";
import { useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDoordash } from "../hook";

export const DoordashDetails = () => {

  const { id } = useParams();
  const {
    state,
    handleInputChange,
    onUpdateWeekdays,
    handleManageModal,
    handleManageInput,
    onUpdateMangeStatus,
    handlePushMenu,
  } = useDoordash({ storeID: id })

  return (
    <>
      <ContentLayout
        title={state.details?.store_name ?? ""}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              {
                name: "Doordash",
                href: "/doordash",
              },
              {
                name: state.details?.store_name ?? "",
              }
            ].filter(Boolean)}
          />
        }
        chipType={"other"}
        statusContent={
          <>
            <Chip
              label={state.details?.connection_status ? "Connected" : "Disconnected"}
              variant="outlined"
              size="small"
              deleteIcon={
                state.details?.connection_status ? (
                  <CheckCircleIcon sx={{ color: "#0F875B !important" }} />
                ) : undefined
              }
              onDelete={state.details?.connection_status ? () => { } : undefined}
              sx={{
                border: "none !important",
                background: state.details?.connection_status ? "#E1FBF2" : '#e6e6e6',
              }}
            />
          </>
        }
      >

        <DoordashGenaralDetails
          state={state}
          handleInputChange={handleInputChange}
          onUpdateWeekdays={onUpdateWeekdays}
          handleManageModal={handleManageModal}
          handleManageInput={handleManageInput}
          onUpdateMangeStatus={onUpdateMangeStatus}
          handlePushMenu={handlePushMenu}
        />
      </ContentLayout>
    </>
  );
};

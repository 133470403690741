import { ContentLayout, CustomeDialog, Loader } from "../../../shared";
import * as React from "react";
import {
  Chip,
  Stack,
  Switch,
  Typography,
  Tab,
  Box,
  Card,
  Grid,
  TextField,
  Button,
  FormGroup,
} from "@mui/material";
import { CustomTable } from "../../../shared";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { useOnlineOrderingList } from "../hooks";
import styled from "@emotion/styled";

export const OnlineOrderingList = () => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    currentTab,
    setCurentTab,
    handleStoreActive,
    handleStoreStatus,
    optionsMenuTemplate,
    handleInputChange,
    handleSubmit,
    isEditable,
    handleModal,
  } = useOnlineOrderingList({
    isList: true,
  });

  const { selectedStore } = state

  const handleChange = (event, newValue) => {
    setCurentTab("onlineOrdering", newValue);
  };

  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const ActionButtons = ({ value, id }) => {
    return (
      <div className="action-buttons" key={id}>
        <Stack
          sx={
            {
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }
          }
        >
          {value?.last_update_at && (
            <Typography
              variant="body2"
              color="#808080"
            >
              Last Update was on {value?.last_update_at}
            </Typography>
          )}
          <InputControl
            id={id}
            type="toggle-switch"
            value={value.status}
            onChange={() => {
              handleModal("store-open", true, value)
            }}
          />

          {isEditable && (
            <Link to={`details/${id}`}>
              <img alt="details" src="/icons/arrow_forward.svg" />
            </Link>
          )}
        </Stack>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Store",
        accessor: "store_name",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap={2}>
              <Typography>{row?.original?.store_name}</Typography>
            </Stack>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Set Up",
        accessor: "name",
        Cell: ({ row }) => {
          let value = row.original

          return (
            <>
              {value?.connection_status === 1 ? (
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => {
                    handleModal("connect", true, row.original)
                  }}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleModal("connect", true, row.original)}
                >
                  Connect
                </Button>
              )}
            </>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "store_id",
        accessor: "store_id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return <ActionButtons value={row.original} id={value} />;
        },
      },
    ],
    [selectedStore, state.isOpenConnect]
  );
  return (
    <>
      <ContentLayout
        title="Online Ordering"
        rightContent={
          <>
            {currentTab === "1" ? (
              ""
            ) : (
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={onSubmit}
                disabled={state.isSaveButtonBusy}
              >
                Save
              </Button>
            )}
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              top: "88px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Stores" value="1" />
              {/* <Tab label="Settings" value="2" /> */}
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <CustomTable
              columns={columns}
              data={state?.onlineOrderingList}
              pagination={false}
              isLoading={state?.isBusy}
              tableId="onlineOrering"
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              {validator.current.purgeFields()}
              {state.isBusy ? (
                <Loader />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ width: "150px" }}
                    >
                      Menu Template
                    </Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Stack width={"50%"} gap="20px">
                      <InputControl
                        type="dropdown"
                        disableClearable
                        options={optionsMenuTemplate ?? []}
                        getOptionLabel={(option) => option.label}
                        value={
                          (optionsMenuTemplate?.length > 0 &&
                            state.dataMenu.menu_id &&
                            optionsMenuTemplate?.find(
                              (c) => c.value === state.dataMenu.menu_id
                            )) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          handleInputChange({
                            target: {
                              name: "menu_id",
                              value: newValue.value,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Menu Template"
                            required
                            error={validator.current.message(
                              "Menu Template",
                              state.dataMenu.menu_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Menu Template",
                              state.dataMenu.menu_id,
                              "required"
                            )}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Card>
          </TabPanel>
        </TabContext>
      </ContentLayout>

      <CustomeDialog
        open={state.isOpenConnect}
        PaperProps={{
          sx: { width: "423px", height: "155px", p: "16px" },
        }}
        title="Alert"
        content={
          <Stack
            mt="8px"
            gap="10px"
            height="100%"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              {state.isModalType === "connect" ?
                `Do you want to ${selectedStore?.connection_status ? "Disconnect" : "Connect"} Online Ordering for ${selectedStore?.store_name} store?` :
                `Do you wish to ${!!selectedStore?.status ? "close" : "open"} Online Ordering services for ${selectedStore?.store_name}${!!selectedStore?.status ? " indefinitely" : ""}?`}
            </Typography>
            <Stack direction="col" justifyContent="end" gap={2}>
              <Button
                // disabled={isButtonLoading}
                variant="text"
                onClick={() => handleModal("connect", false, null)}
              >
                No
              </Button>
              <LoadingButton
                // loading={isButtonLoading}
                variant="contained"
                onClick={() => {
                  if (state.isModalType === "connect") {
                    handleStoreActive(selectedStore, selectedStore?.connection_status ? false : true)
                  } else {
                    handleStoreStatus(selectedStore, selectedStore?.status ? false : true)
                  }
                }}
              >
                Yes
              </LoadingButton>
            </Stack>
          </Stack>
        }
      />
    </>
  );
};

import { Box } from "@material-ui/core";
import { Stack, Button } from "@mui/material";
import React, { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import { CategoryItem } from "./CategoryItem";

export const Categories = ({
  data,
  selectedCategory,
  changeCategory,
  onAddNewCategory,
  moveCategory,
}) => {
  const categoryRef = useRef(null);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  return (
    <Stack>
      <Box
        sx={{
          height: "56px",
          borderBottom: "1px solid #E6E6E6",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          onClick={() =>
            handleHorizantalScroll(categoryRef.current, 50, 100, -10)
          }
          sx={{
            width: "56px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRight: "1px solid #E6E6E6",
          }}
        >
          <img src="/icons/ic_left.svg" />
        </Box>
        <Box
          ref={categoryRef}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
            padding: "10px",
            overflow: "hidden",
          }}
        >
          {data.map((item, i) => (
            <CategoryItem
              changeCategory={changeCategory}
              item={item}
              index={i}
              selectedCategory={selectedCategory}
              moveCategory={moveCategory}
            />
          ))}

          <Button size="small" onClick={onAddNewCategory}>
            <AddIcon />
          </Button>
        </Box>
        <Box
          onClick={() =>
            handleHorizantalScroll(categoryRef.current, 50, 100, 10)
          }
          sx={{
            width: "56px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderLeft: "1px solid var(--gray-gray-90, #E6E6E6)",
          }}
        >
          <img src="/icons/ic_right.svg" />
        </Box>
      </Box>
    </Stack>
  );
};

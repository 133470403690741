import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage, infoMessage } from "../../../utils";
import {
  uploadAllMenu,
  uploadPushMenu,
} from "../api/ubereatsApi";
import { useActivityLog, usePermission, useTabs } from "../../../shared";
import { AppContext } from "../../../store";
import { useMenuTemplate } from "../../MenuTemplate";
import { createTemplate, showChannel } from "../../Menulog/api/menuLogApi";
import { editStoreConnectionApi, editStoreStatusApi, getIntegrationListApi } from "../../OnlineOrdering/api/onlineOrderingApi";

export const useUbereatsList = ({ isList }) => {
  const uberEatsID = 102
  const isEditable = usePermission("ubereats-modify");
  const { addActivityLog } = useActivityLog();
  const { appState } = useContext(AppContext);
  const { ubereats: currentTab } = appState.currentTabs;
  const { setCurentTab } = useTabs();
  const { state: stateMenuTempalte } = useMenuTemplate({ isList: false });
  const optionsMenuTemplate = stateMenuTempalte?.menuTemplates?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
  const [state, setState] = useImmer({
    isBusy: false,
    isOpen: false,
    isAllSaveButton: false,
    isSaveButton: false,
    isSaveButtonBusy: false,
    store_id: null,
    data: {
      store_id: "",
      ubereats_store_id: "",
      ubereats: 0,
    },
    isErrorOpen: false,
    errorMessages: null,
    dataMenu: {
      channel_id: 102,
      menu_id: "",
    },
    uberEatsList: [],
    isOpenConnect: false,
    isModalType: "",
    selectedStore: null,
  });

  const handleAllMenu = async () => {
    try {
      setState((draft) => {
        draft.isAllSaveButton = true;
      });
      const res = await uploadAllMenu();
      if (res.status === 200) {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        infoMessage(res.data.message);
      } else {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isAllSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };
  const activityLog = (id, data) => {
    const filteredNewData = data?.find((item) => item.store_id === id);

    addActivityLog({
      templateId: 5,
      parameters: { StoreName: filteredNewData?.store_name },
      uniqueParameter: id,
      data: { newLogData: filteredNewData, oldLogData: {} },
    });
  };

  const handlePushMenu = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
        draft.store_id = id;
      });
      const res = await uploadPushMenu(id);
      activityLog(id);
      infoMessage(res.data.message);
      getUberEatsList();
      if (res.status === 200) {
        setState((draft) => {
          draft.isSaveButton = false;
        });
      } else {
        setState((draft) => {
          draft.isSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data.ubereats_store_id = value;
    });
  };

  const isErrorModal = (v, text) => {
    setState((draft) => {
      draft.isErrorOpen = v;
      draft.errorMessages = text;
    });
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.dataMenu[name] = value;
    });
  };
  const handleSubmit = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createTemplate(state.dataMenu);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getTemplatesDetails = async (channel_id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await showChannel(channel_id);
      if (res.data) {
        setState((draft) => {
          draft.dataMenu.menu_id = res?.data?.id;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (currentTab === "2") {
      getTemplatesDetails(state?.dataMenu?.channel_id);
    }
  }, [currentTab]);

  useEffect(() => {
    if (isList) {
      getUberEatsList();
    }
  }, [isList]);

  const getUberEatsList = async (searchText) => {

    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });

      const res = await getIntegrationListApi(uberEatsID);

      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.initialLoad = false;
          draft.uberEatsList = res.data;
        });
      }
    } catch (err) {
      console.log(err);

      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };

  const handleModal = (type, state, data) => {
    console.log(type, state, data);

    switch (type) {
      case "connect":
        setState((draft) => {
          draft.isOpenConnect = state;
          draft.selectedStore = data;
          draft.isModalType = type;
          draft.isOpen = false;
        });
        break;
      case "connect-id":
        setState((draft) => {
          draft.isOpen = state;
          draft.selectedStore = data;
          draft.data.ubereats_store_id = "";
        })
        break;
      case "store-open":
        setState((draft) => {
          draft.isOpenConnect = state;
          draft.selectedStore = data;
          draft.isModalType = type;
        });
        break;
    }
  };

  const handleStoreStatus = async (value, e, type) => {
    let payload = {
      status: e ? 1 : 0,
    };
    try {
      const res = await editStoreStatusApi(uberEatsID, value?.store_id, payload);
      if (res.success) {
        successMessage(res.message);
        handleModal("connect", false, null)
        getUberEatsList();
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleStoreActive = async (value, e, type) => {
    console.log(value, e);

    let payload = {
      connection_status: e ? 1 : 0,
      external_store_id: state.data.ubereats_store_id
    };
    try {
      const res = await editStoreConnectionApi(uberEatsID, value?.store_id, payload);
      if (res.success) {
        successMessage(res.message);
        handleModal("connect", false, null)
        getUberEatsList();
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };


  return {
    state,
    handleAllMenu,
    handlePushMenu,
    onChange,
    isEditable,
    isErrorModal,
    currentTab,
    setCurentTab,
    optionsMenuTemplate,
    handleInputChange,
    handleSubmit,
    handleModal,
    handleStoreActive,
    handleStoreStatus,
  };
};

import { ContentLayout, CustomeDialog, Loader } from "../../../shared";
import * as React from "react";
import {
  Link as LinkButton,
  Chip,
  Stack,
  Typography,
  Button,
  TextField,
  Box,
  Tab,
  Grid,
  Card,
} from "@mui/material";
import { CustomTable } from "../../../shared";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { sortItems } from "../../../utils";
import SimpleReactValidator from "simple-react-validator";
import InputControl from "../../../shared/components/InputControl";
import { DoordashSetupDialog } from "../components/DoordashSetupDialog";
import { Link } from "react-router-dom";
import { useDoordashList } from "../hook";

export const DoordashList = () => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    handleAllMenu,
    handlePushMenu,
    isEditable,
    isErrorModal,
    currentTab,
    setCurentTab,
    optionsMenuTemplate,
    handleInputChange,
    handleSubmit,
    handleSetup,
    onChange,
    handleModal,
    handleStoreActive,
    handleStoreStatus,
  } = useDoordashList({
    isList: true,
  });

  const { selectedStore } = state

  const handleChange = (event, newValue) => {
    setCurentTab("doordash", newValue);
  };
  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const ActionButtons = ({ value }) => {
    let isError = value.doordash_message;
    const {
      id,
      doordash_message,
      doordash_status,
      store_id,
      doordash_last_pushed_at,
      doordash_onboard_status,
      connection_status,
      status,
      last_update_at,
      lastest_message,
    } = value;
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {lastest_message ? (
          <Stack>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 500,
                color: "#C42B1C",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => isErrorModal(true, doordash_message)}
            >
              Show Error
            </Typography>

            <Typography variant="caption" color="#808080">
              The Last update was on {value?.last_update_at} failed.
            </Typography>
          </Stack>
        ) : null}

        {connection_status === 1 && status === 1 ? (
          <>
            {state.isSaveButton && state?.store_id === store_id ? (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="4px"
                  className="uber-loader"
                >
                  <Loader />
                  <Typography
                    variant="subtitle2"
                    fontWeight={500}
                    color="#C07302"
                  >
                    Uploading...
                  </Typography>
                </Stack>
              </>
            ) : (
              <div onClick={() => handlePushMenu(store_id, state.doordashData)}>
                <Stack>
                  <Stack
                    direction="row"
                    className="hover-icon-link"
                    gap={1}
                    sx={{ width: "fit-content" }}
                    alignSelf="end"
                  >
                    <img src="/icons/icon_blue_refresh.svg" />
                    <LinkButton underline="none" component="button" variant="body2">
                      <Typography fontWeight={600}>Push Menu</Typography>
                    </LinkButton>
                  </Stack>
                  {last_update_at ? (
                    <Typography variant="caption" color="#808080">
                      The Last update was on {last_update_at}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Stack>
              </div>
            )}
          </>
        ) : (
          ""
        )}
        {isEditable && (
          <>
            <InputControl
              id={id}
              type="toggle-switch"
              value={value.status}
              onChange={() => {
                handleModal("store-open", true, value)
              }}
            />
            <Link to={`details/${store_id}`}>
              <img alt="details" src="/icons/arrow_forward.svg" />
            </Link>
          </>
        )}
      </Box>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Store",
        accessor: "store_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        Cell: ({ value, row }) => {
          return (
            <Stack direction="row" gap={2}>
              <Typography> {value}</Typography>
              {row?.original?.ubereats_store_id && (
                <Chip
                  label={row?.original?.ubereats_store_id}
                  color="success"
                  variant="outlined"
                  size="small"
                  disabled
                  sx={{ mr: 1, borderRadius: "8px" }}
                />
              )}
            </Stack>
          );
        },
      },
      {
        Header: "Set Up",
        accessor: "name",
        Cell: ({ row }) => {
          let value = row.original

          return (
            <>
              {value?.connection_status === 1 ? (
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => handleModal("connect", true, row.original)}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleModal("connect-id", true, row?.original)}
                >
                  Connect
                </Button>
              )}
            </>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <ActionButtons value={row.original} id={row.original.store_id} />
          );
        },
      },
    ],
    [state.isSaveButton, state.doordashData]
  );

  return (
    <>
      <ContentLayout
        title="Doordash"
        rightContent={
          <>
            {currentTab === "1" ? (
              <LoadingButton
                type="button"
                size="medium"
                variant="contained"
                onClick={handleAllMenu}
                disabled={state?.isSaveButton}
                loading={state?.isAllSaveButton}
                startIcon={<img src="/icons/icon_refresh.svg" />}
              >
                Push Menu
              </LoadingButton>
            ) : (
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={onSubmit}
                disabled={state.isSaveButtonBusy}
              >
                Save
              </Button>
            )}
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              top: "88px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Stores" value="1" />
              {/* <Tab label="Settings" value="2" /> */}
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <CustomTable
              columns={columns}
              data={state?.doordashList}
              pagination={false}
              isLoading={state?.isBusy}
              tableId="doordash"
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              {validator.current.purgeFields()}
              {state.isBusy ? (
                <Loader />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ width: "150px" }}
                    >
                      Menu Template
                    </Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Stack width={"50%"} gap="20px">
                      <InputControl
                        type="dropdown"
                        disableClearable
                        options={optionsMenuTemplate ?? []}
                        getOptionLabel={(option) => option.label}
                        value={
                          (optionsMenuTemplate?.length > 0 &&
                            state.dataMenu.menu_id &&
                            optionsMenuTemplate?.find(
                              (c) => c.value === state.dataMenu.menu_id
                            )) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          handleInputChange({
                            target: {
                              name: "menu_id",
                              value: newValue.value,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Menu Template"
                            required
                            error={validator.current.message(
                              "Menu Template",
                              state.dataMenu.menu_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Menu Template",
                              state.dataMenu.menu_id,
                              "required"
                            )}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Card>
          </TabPanel>
        </TabContext>
      </ContentLayout>
      <CustomeDialog
        open={state.isErrorOpen}
        handleClose={() => isErrorModal(false, null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "423px", p: "24px 24px 16px" },
        }}
        isHeader={false}
        content={
          <Stack gap="12px">
            <Typography variant="subtitle2" fontWeight={600}>
              Error
            </Typography>
            <Typography variant="subtitle2">{state.errorMessages}</Typography>
            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "end",
              }}
              onClick={() => isErrorModal(false, null)}
            >
              Ok
            </Button>
          </Stack>
        }
      />
      <CustomeDialog
        open={state.isDialogOpen}
        handleClose={() => handleSetup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "618px", p: "16px" },
        }}
        title="Onboard Setup"
        content={
          <DoordashSetupDialog
            onChange={onChange}
            state={state}
            // handleSetupDoordash={handleSetupDoordash}
            handleSetupDoordash={() => handleStoreActive(selectedStore, selectedStore?.connection_status ? false : true)}
          />
        }
      />

      <CustomeDialog
        open={state.isOpenConnect}
        PaperProps={{
          sx: { width: "423px", height: "155px", p: "16px" },
        }}
        title="Alert"
        content={
          <Stack
            mt="8px"
            gap="10px"
            height="100%"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              {state.isModalType === "connect" ?
                `Do you want to ${selectedStore?.connection_status ? "Disconnect" : "Connect"} Uber Eats for ${selectedStore?.store_name} store?` :
                `Do you wish to ${!!selectedStore?.status ? "close" : "open"} Uber Eats services for ${selectedStore?.store_name}${!!selectedStore?.status ? " indefinitely" : ""}?`}
            </Typography>
            <Stack direction="col" justifyContent="end" gap={2}>
              <Button
                // disabled={isButtonLoading}
                variant="text"
                onClick={() => handleModal("connect", false, null)}
              >
                No
              </Button>
              <LoadingButton
                // loading={isButtonLoading}
                variant="contained"
                onClick={() => {
                  if (state.isModalType === "connect") {
                    handleStoreActive(selectedStore, selectedStore?.connection_status ? false : true)
                  } else {
                    handleStoreStatus(selectedStore, selectedStore?.status ? false : true)
                  }
                }}
              >
                Yes
              </LoadingButton>
            </Stack>
          </Stack>
        }
      />
    </>
  );
};

import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useMemo } from "react";
import { GarbageProduct } from "./GarbageProduct";
import { useDrop } from "react-dnd";
import InputControl from "../../../shared/components/InputControl";
import { useState } from "react";
import { AllProducts } from "./AllProducts";

export const ProductList = ({
  moveItemToGarbage,
  state,
  state: { allProducts },
}) => {
  const [searchText, setSearchText] = useState("");
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "prd",
      drop: (item, monitor) => moveItemToGarbage(item),
      // canDrop: (item) => {},
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    []
  );

  const filteredProducts = useMemo(() => {
    return allProducts
      .map((category) => ({
        ...category,
        products: category.products.filter((item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase())
        ),
      }))
      .filter((category) => category.products.length > 0);
  }, [allProducts, searchText]);

  return (
    <Stack
      sx={{
        borderLeft: "1px solid #E6E6E6",
        height: "calc(100vh - 100px)",
        overflow: "auto",
      }}
      ref={drop}
    >
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          borderBottom: "1px solid #E6E6E6",
          padding: "12px 16px",
        }}
      >
        Products
      </Box>
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          borderBottom: "1px solid #E6E6E6",
          padding: "6px 16px",
        }}
      >
        <InputControl
          type="table-search"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClear={() => setSearchText("")}
          sx={{ height: "44px", backgroundColor: "rgba(0, 0, 0, 0.06)" }}
        />
      </Box>
      <AllProducts
        products={filteredProducts}
        searchText={searchText}
        state={state}
      />
    </Stack>
  );
};

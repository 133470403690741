import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import {
  Tab,
  Box,
  Chip,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { OnlineOrderingGenaralDetails, OpeningAndClosingHourTable } from "../components";
import { OnlineOrderingSettings } from "../components/OnlineOrderingSettings";
import { useOnlineOrdering } from "../hooks/useOnlineOrdering";
import { useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const OnlineOrderingDetails = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }
  const { id } = useParams();
  const {
    state,
    handleInputChange,
    onUpdateWeekdays,
    handleManageModal,
    handleManageInput,
    onUpdateMangeStatus,
  } = useOnlineOrdering({ storeID: id })

  return (
    <>
      <ContentLayout
        title={state.details?.store_name ?? ""}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              {
                name: "Online Ordering",
                href: "/online-ordering",
              },
              {
                name: state.details?.store_name ?? "",
              }
            ].filter(Boolean)}
          />
        }
        chipType={"other"}
        statusContent={
          <>
            <Chip
              label={state.details?.connection_status ? "Connected" : "Disconnected"}
              variant="outlined"
              size="small"
              deleteIcon={
                state.details?.connection_status ? (
                  <CheckCircleIcon sx={{ color: "#0F875B !important" }} />
                ) : undefined
              }
              onDelete={state.details?.connection_status ? () => {} : undefined}
              sx={{
                border: "none !important",
                background: state.details?.connection_status ? "#E1FBF2" : '#e6e6e6',
              }}
            />
          </>
        }
      >
        <TabContext value={value}>
          <Box
            className="sticky-Tab"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              top: "88px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TabList onChange={handleChange}>
              <Tab label="General" value="1" />
              {/* <Tab label="Settings" value="2" /> */}
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <OnlineOrderingGenaralDetails
              state={state}
              handleInputChange={handleInputChange}
              onUpdateWeekdays={onUpdateWeekdays}
              handleManageModal={handleManageModal}
              handleManageInput={handleManageInput}
              onUpdateMangeStatus={onUpdateMangeStatus}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <OnlineOrderingSettings />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};

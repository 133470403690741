import { API } from "../../../utils";

export const getOnlineOrdering = async (query) => {
  const res = await API.get("online_ordering/stores", query);
  return res.data;
};
export const editStoreStatus = async (payload) => {
  const res = await API.put(`online_ordering/stores`, payload);
  return res.data;
};

export const getIntegrationListApi = async (intID, query) => {
  const res = await API.get(`integrations/${intID}/stores`, query);
  return res.data;
};


export const editStoreConnectionApi = async (intID, storeID, payload) => {
  const res = await API.put(`integrations/${intID}/stores/${storeID}/update_connection`, payload);
  return res.data;
};

export const editStoreStatusApi = async (intID, storeID, payload) => {
  const res = await API.put(`integrations/${intID}/stores/${storeID}/update_status`, payload);
  return res.data;
};

export const getHourListApi = async (intID, storeID) => {
  const res = await API.get(`integrations/${intID}/stores/${storeID}/list_hours`);
  return res.data;
};

export const getIntegrationDetailsApi = async (intID, storeID) => {
  const res = await API.get(`integrations/${intID}/stores/${storeID}`);
  return res.data;
};

export const updateStoreHourApi = async (intID, storeID, payload) => {
  const res = await API.put(`integrations/${intID}/stores/${storeID}/hour_update`, payload);
  return res.data;
};

export const updateManageStatusApi = async (intID, storeID, payload) => {
  const res = await API.put(`integrations/${intID}/stores/${storeID}/manage_status`, payload);
  return res.data;
};
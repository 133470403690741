import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { OnlineOrderingDetails, OnlineOrderingList } from "./containers";

export const OnlineOrdering = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<OnlineOrderingList />} />

        <Route element={<ProtectedRoute allowedRoute={["online-ordering-view"]} />}>
          <Route path="/details/:id" element={<OnlineOrderingDetails />} />
        </Route>
        {/* <Route element={<ProtectedRoute allowedRoute={["discount-view"]} />}>
          <Route
            path="/details/:id/menu_name/:cid"
            element={<MenuTemplateProductList />}
          />
        </Route> */}
      </Routes>
    </>
  );
};

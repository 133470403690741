import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import Avadar_img from "../../../assets/images/Icons/Avadar_img.png";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { CustomDetailsList, Loader } from "../../../shared";

export const StoreStatusManageModal = (props) => {
  const {
    data,
    handleManageModal,
    onChange,
    onUpdateMangeStatus,
  } = props

  const statusOption = [
    {
      label: "Closed Indefinitely",
      value: 3,
    },
    {
      label: "Closed Today",
      value: 2,
    },
    {
      label: "Open",
      value: 1,
    },
  ]

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },

      validators: {
        openTime: {
          message: "Selected time is not a valid date",
          rule: (value) => {
            return moment(value, "HH:mm:ss").isValid();
          },
        },
        closeTime: {
          message: "The close time should be greater than open time",
          rule: (value) => {
            const { openTime, closeTime } = value;
            return moment(openTime, "HH:mm").isBefore(
              moment(closeTime, "HH:mm")
            );
          },
        },
      },
    })
  );

  const onSubmitStatus = () => {
    if (validator.current.allValid()) {
      onUpdateMangeStatus();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <Stack
      sx={{
        gap: "16px",
        mt: "16px"
      }}>
      <InputControl
        type="select"
        name="external_manage_status"
        label="Status"
        value={data.external_manage_status ?? ""}
        optionValue={statusOption}
        onChange={(e, v) => onChange(e)}
      />

      {data.external_manage_status == 1 ? (
        <Stack
          sx={{
            flexDirection: "row",
            gap: "10px",
          }}
        >

          <InputControl
            name="startAt"
            ampm={false}
            type="timePicker"
            value={moment(data.open_at, "HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e) => {
              onChange(
                {
                  target: {
                    name: "open_at",
                    value: moment(e, "HH:mm").format("HH:mm") ?? null,
                  },
                },
              );
            }}
            error={validator.current.message(
              `Opening Time`,
              data.open_at,
              "required|openTime"
            )}
            helperText={validator.current.message(
              `Opening Time `,
              data.open_at,
              "required|openTime"
            )}
          />
          < InputControl
            name="startAt"
            ampm={false}
            label="Closing Time Today"
            type="timePicker"
            value={moment(data.close_at, "HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e) => {
              onChange(
                {
                  target: {
                    name: "close_at",
                    value: moment(e, "HH:mm").format("HH:mm") ?? null,
                  },
                },
              );
            }}
            error={validator.current.message(
              `Closing Time`,
              {
                openTime: data.open_at,
                closeTime: data.close_at,
              },
              "required|closeTime"
            )}
            helperText={validator.current.message(
              `Closing Time`,
              {
                openTime: data.open_at,
                closeTime: data.close_at,
              },
              "required|closeTime"
            )}
          />
        </Stack>
      ) : ""}
      <Stack>
        <InputControl
          name="close_message"
          label="Store closing message"
          value={data.close_message ?? ""}
          onChange={onChange}
        />
      </Stack>
      <Stack direction="col" justifyContent="end" gap={2}>
        <Button
          variant="text"
          onClick={() => handleManageModal(false)}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onSubmitStatus}
        >
          Save
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

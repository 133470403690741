import { getSalesChannels, updateSalesChannel, updateSalesChannelApi } from "../..";
import { getPriceLevel } from "../../PriceLevels/api/priceLevelApi";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../../../utils";
import { useActivityLog } from "../../../shared";

export const useStoreSaleChannels = ({ isPriceLevel, storeName }) => {
  const { addActivityLog } = useActivityLog();
  const { id } = useParams();
  const [state, setState] = useImmer({
    isBusy: false,
    PriceLevels: [],
    data: [],
    storeName: "",
  });

  useEffect(() => {
    getSalesChannelList(id);
  }, []);

  const getSalesChannelList = async (id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getSalesChannels(id);
      if (res.success) {
        setState((draft) => {
          draft.data = res.data;
          draft.isBusy = false;
        });
      }
    } catch {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const activityLogPriceLevelChange = (item, priceLevelId, type) => {
    const newData = item;
    const { channel } = newData;
    const priceLevelFilter = state.PriceLevels.find(
      (pricelevel) => pricelevel.id === Number(priceLevelId)
    );

    const parameters = {
      StoreId: id,
      StoreName: storeName,
      SalesChannelName: channel,
      PriceLevelName: priceLevelFilter?.name,
    };

    addActivityLog({
      templateId: type === "price-level" ? 26 : 27,
      parameters: parameters,
      uniqueParameter: id,
      data: {
        newLogData: parameters,
        oldLogData: newData,
      },
    });
  };
  const onSalesChannelChange = async (item, type, priceLevelId, index) => {

    const { price_level_id, status, channel_id } = item;
    try {
      let payload = {};
      if (type === "price-level") {
        setState((draft) => {
          draft.data[index].price_level_id = priceLevelId;
          draft.data[index].status = status;
        });
        payload = {
          price_level_id: priceLevelId,
          status,
        };
      } else if (type === "status") {
        setState((draft) => {
          draft.data[index].status = status === 1 ? 0 : 1;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  useEffect(() => {
    if (isPriceLevel) {
      getPriceLeveles();
    }
  }, [isPriceLevel]);

  const getPriceLeveles = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getPriceLevel();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.PriceLevels = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const onSaveSalesChannel = async () => {

    try {
      let payload = {
        channels: state.data.map((v) => {
          return {
            id: v.channel_id,
            price_level_id: v.price_level_id,
            status: v.status
          }
        })
      }
      const res = await updateSalesChannelApi(id, payload);
      if (res.success) {
        // activityLogPriceLevelChange(item, priceLevelId, type);
        successMessage(res.message)
        getSalesChannelList(id);
      }else{
        errorMessage(res.message)
      }
    } catch (err) {

      errorMessage(err.response.data.message);
    }
  }

  return { state, onSalesChannelChange, onSaveSalesChannel };
};

import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import Avadar_img from "../../../assets/images/Icons/Avadar_img.png";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { CustomDetailsList, CustomeDialog, Loader } from "../../../shared";
// import { OpeningAndClosingHourTable } from "./OpeningAndClosingHourTable";
// import { useOnlineOrdering } from "../hooks/useOnlineOrdering";
import { useParams } from "react-router-dom";
import { OpeningAndClosingHourTable, StoreStatusManageModal } from "../../OnlineOrdering/components";
// import { StoreStatusManageModal } from "./StoreStatusManageModal";

export const UberEatsGenaralDetails = (props) => {

  const { state,
    handleInputChange,
    onUpdateWeekdays,
    handleManageModal,
    handleManageInput,
    onUpdateMangeStatus,
    handlePushMenu,
  } = props

  const {
    details,
    isBusy,
    manageModal,
    manageData,
  } = state


  const column = [
    {
      title: "Uber Eats Store ID",
      accessor: "external_store_id",
    },
    {
      title: "The last update was on",
      // accessor: "last_update_at",
      accessor: (row) => {
        return <Stack sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <div>{row.last_update_at}</div>
          <div>
            {state.isSaveButton ? (
              <Stack
                direction="row"
                alignItems="center"
                gap="4px"
                className="uber-loader"
              >
                <Loader />
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="#C07302"
                >
                  Uploading...
                </Typography>
              </Stack>
            ) : (
              <Stack
                direction="row"
                className="hover-icon-link"
                gap={1}
                sx={{ width: "fit-content" }}
                alignSelf="end"
                onClick={handlePushMenu}
              >

                <img src="/icons/icon_blue_refresh.svg" />
                <Link underline="none" component="button" variant="body2">
                  <Typography fontWeight={600}>Push Menu</Typography>
                </Link>
              </Stack>)}
          </div>
        </Stack>;
      },
    },
  ]

  const column2 = [
    {
      title: "Holidays",
      accessor: (val) => {
        return (
          <Stack sx={{
            flexDirection: "row",
            gap: "8px",
          }}
          >
            {val.holidayList.map((v, i) => (
              <Chip
                key={i}
                label={v.holiday}
                variant="filled"
                size="small"
              />
            ))}
          </Stack>
        );
      },
    },
    {
      title: "Public Holidays",
      accessor: (val) => {
        return (
          <Stack sx={{
            flexDirection: "row",
            gap: "8px",
          }}
          >
            {val.publicHolidayList.map((v, i) => (
              <Chip
                key={i}
                label={v.holiday}
                variant="filled"
                size="small"
              />
            ))}
          </Stack>
        );
      },
    },
  ]

  return (
    <>
      <Stack sx={{ mb: "24px" }}>
        {isBusy ?
          <Loader /> :
          (
            <>
              <CustomDetailsList
                columns={column}
                data={details}
                title="Details"
              />
              <Card sx={{ mb: "24px" }}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "24px"
                  }}
                >
                  <Stack gap="8px" >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 600,
                        lineHeight: "19px",
                      }}
                    >
                      {details.external_manage_status === 1 ?
                        `The store is Open Today from ${moment(details.open_at, "HH:mm:ss").format("HH:mm")} to ${moment(details.close_at, "HH:mm:ss").format("HH:mm")}.`
                        : details.external_manage_status === 2 ? "The store will be closed Today." :
                          `The store is Closed Indefinitely`}

                    </Typography>
                    {details.close_message ? (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: 500,
                          lineHeight: "19px",
                          p: "6px 12px",
                          borderRadius: "2px",
                          bgcolor: "#FFF4CE",
                          color: "#C07302",
                        }}
                      >
                        Store Closing Message: “{details.close_message}”.
                      </Typography>
                    ) : ""}
                  </Stack>
                  <Button
                    variant="contained"
                    onClick={() => handleManageModal(true)}
                  >
                    Manage
                  </Button>
                </Stack>
              </Card>
              <OpeningAndClosingHourTable
                handleInputChange={handleInputChange}
                state={state}
                onUpdateWeekdays={onUpdateWeekdays}
              />
              <CustomDetailsList
                columns={column2}
                data={state.holidayData}
                title={
                  <>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      lineHeight="19px"
                    >
                      Holidays
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="#636363"
                    >
                      These holidays have been configured by the store through the super admin panel.
                    </Typography>
                  </>
                }
              />
            </>
          )}
      </Stack>
      <CustomeDialog
        open={manageModal}
        PaperProps={{
          sx: { width: "423px", minHeight: "155px", p: "16px" },
        }}
        title="Manage"
        content={
          <StoreStatusManageModal
            data={manageData}
            handleManageModal={handleManageModal}
            onChange={handleManageInput}
            onUpdateMangeStatus={onUpdateMangeStatus}
          />
        }
      />
    </>
  );
};

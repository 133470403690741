import React from "react";
import { Route, Routes } from "react-router-dom";
import { DoordashDetails, DoordashList } from "./containers";
import { ProtectedRoute } from "../../shared";

export const Doordash = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DoordashList />} />
        <Route element={<ProtectedRoute allowedRoute={["ubereats-view"]} />}>
          <Route path="/details/:id" element={<DoordashDetails />} />
        </Route>
      </Routes>
    </>
  );
};

import React from "react";
import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  usePermission,
} from "../../../shared";
import { Button, Chip, Box, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import { useProductSort } from "../hooks/useProductSort";

export const TemplateList = () => {
  const IsCreatable = usePermission("keypad-create");
  const IsEditable = usePermission("keypad-modify");
  const IsDeletable = usePermission("keypad-delete");
  const {
    state: {
      templates,
      isBusy,
      isOpen,
      isLoading,
      duplicateId,
      isDeleteButtonBusy,
    },
    handleModal,
    onConfirmDelete,
    onDuplicate,
  } = useProductSort({ isList: true, id: null });
  const navigate = useNavigate();
  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {IsEditable && (
          <>
            {" "}
            <Button
              className="btn"
              size="small"
              onClick={editAction}
              sx={{
                "&:hover": {
                  bgcolor: "rgba(0, 0, 0, 0.06)",
                },
              }}
              color="info"
            >
              Edit Stores
            </Button>{" "}
          </>
        )}
        {IsCreatable &&
          (isLoading && duplicateId === id ? (
            <Stack
              direction="row"
              alignItems="center"
              gap="4px"
              className="duplicate-loader"
            >
              <Loader />
            </Stack>
          ) : (
            <img
              onClick={() => onDuplicate(id)}
              alt="details"
              src="/icons/ic_copy.svg"
            />
          ))}
        {IsDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        {IsEditable && (
          <Link to={`/sales-screen-layout/edit/${id}`}>
            <img alt="details" src="/icons/arrow_forward.svg" />
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Template Name",
        Cell: ({ cell }) => {
          return (
            <Stack direction="row" gap={1}>
              <Typography variant="body2">{cell.row.original.name}</Typography>
              {cell.row.original.isDefault && (
                <Chip
                  label="Default"
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{ bgcolor: "#1560D414", border: "none" }}
                  disabled={false}
                />
              )}
            </Stack>
          );
        },
      },
      {
        Header: "Stores",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ cell }) => {
          const stores = cell.row.original.stores;
          return (
            <Box>
              {stores?.slice(0, 5).map((data) => {
                return (
                  <Chip
                    sx={{ mr: 1, borderRadius: "8px" }}
                    label={data.name}
                    variant="filled"
                    size="small"
                  />
                );
              })}
              {stores?.length > 5 && (
                <Chip
                  sx={{ borderRadius: "8px" }}
                  label={`+${stores?.length - stores?.slice(0, 5)?.length}`}
                  variant="filled"
                  size="small"
                />
              )}
            </Box>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <ActionButtons
              id={value}
              deleteAction={() => handleModal(value)}
              editAction={() => navigate(`/keypad/details/${value}`)}
            />
          );
        },
      },
    ],
    [isLoading]
  );
  return (
    <>
      <ContentLayout
        title="Keypad"
        rightContent={
          <>
            {IsCreatable && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/sales-screen-layout/create")}
              >
                Template
              </Button>
            )}
          </>
        }
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                {
                  name: "Dashboard",
                  href: "/",
                },
                { name: "Keypad" },
              ]}
            />
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={templates}
          isLoading={isBusy}
          tableId="templates_list"
          onRowLink={(e) => {
            return `/sales-screen-layout/edit/${e.id}`;
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={isOpen}
        isDeleteButton={isDeleteButtonBusy}
        handleClose={handleModal}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

import { API } from "../../../utils";

export const getStoreList = async (query) => {
  const res = await API.get("stores", query);
  return res.data;
};
export const createStore = async (payload) => {
  const res = await API.post(`stores`, payload);
  return res.data;
};
export const getStoreDetailsById = async (id) => {
  const res = await API.get(`stores/${id}`);
  return res.data;
};

export const editStores = async (id, payload) => {
  const res = await API.post(`stores/${id}`, payload);
  return res.data;
};

export const deleteStoreById = async (id) => {
  const res = await API.delete(`stores/${id}`);
  return res.data;
};

export const getSalesChannels = async (id) => {
  const res = await API.get(`stores/${id}/sales-channel`);
  return res.data;
};

export const updateSalesChannel = async (storeId, channelId, payload) => {
  const res = await API.put(
    `stores/${storeId}/sales-channel/${channelId}`,
    payload
  );
  return res.data;
};

export const updateSalesChannelApi = async (storeId, payload) => {
  const res = await API.put(
    `stores/${storeId}/sales-channel/`,
    payload
  );
  return res.data;
};

export const getProductsInStore = async (id, search, category) => {
  let url = `stores/${id}/products?`;
  if (search) {
    url += `search=${search}`;
  }
  if (category) {
    url += `&category_id=${category}`;
  }
  const res = await API.get(url);
  return res.data;
};
export const deleteProductFromStores = async (storeId, productsId) => {
  const res = await API.delete(`stores/${storeId}/products/${productsId}`);
  return res.data;
};
export const assignProductToStore = async (id, payload) => {
  const res = await API.put(`stores/${id}/products`, payload);
  return res.data;
};

export const copyStore = async (payload, id) => {
  const res = await API.post(`stores/${id}/duplicate`, payload);
  return res.data;
};

export const getBumpScreen = async (query) => {
  const res = await API.get(`bump_screens`, query);
  return res.data;
};

export const UnpairBumpScreenData = async (id) => {
  const res = await API.put(`bump_screens/${id}/remove`);
  return res.data;
};
export const createBumpScreen = async (payload) => {
  const res = await API.post(`bump_screens`, payload);
  return res.data;
};
export const updateBumpScreen = async (id, payload) => {
  const res = await API.put(`bump_screens/${id}`, payload);
  return res.data;
};
export const getBumpScreenDetail = async (id) => {
  const res = await API.get(`bump_screens/${id}`);
  return res.data;
};
export const deleteBumpScreen = async (id) => {
  const res = await API.delete(`bump_screens/${id}`);
  return res.data;
};
export const getBumpScreenTag = async (data) => {
  const res = await API.get(`tags?type=bump_screen`);
  return res.data;
};

export const getStoresBankAccount = async (id) => {
  const res = await API.get(`stores/${id}/store_accounts`);
  return res.data;
};
export const deleteAccountsFromProduct = async (StoreId, BAId) => {
  const res = await API.delete(`stores/${StoreId}/store_accounts/${BAId}`);
  return res.data;
};

export const createBankAccount = async (StoreId, payload) => {
  const res = await API.post(`stores/${StoreId}/store_accounts`, payload);
  return res.data;
};

export const updateBankAccount = async (StoreId, BAId, payload) => {
  const res = await API.put(
    `stores/${StoreId}/store_accounts/${BAId}`,
    payload
  );
  return res.data;
};

export const updateDefaultBankAccount = async (StoreId, BAId) => {
  const res = await API.post(
    `stores/${StoreId}/store_accounts/${BAId}/default`
  );
  return res.data;
};
export const getCategorywiseProductsInStore = async (id) => {
  const res = await API.get(`product/list`);
  return res.data;
};

export const getUsersInStore = async (id, query) => {
  const res = await API.get(`stores/${id}/users`, query);
  return res.data;
};

export const deleteUsersInStore = async (id, storeId) => {
  const res = await API.delete(`users/${id}/store/${storeId}`);
  return res.data;
};

export const updateStoreOpeningClosing = async (id, payload) => {
  const res = await API.post(`stores/${id}/store-daily-timing`, payload);
  return res.data;
};

export const getHolidaysInStore = async (id) => {
  const res = await API.get(`stores/${id}/holidays`);
  return res.data;
};

export const getPublicHolidaysInStore = async (id,) => {
  const res = await API.get(`stores/${id}/public-holidays`);
  return res.data;
};

export const updateStoreHoliday = async (id, payload) => {
  const res = await API.post(`stores/${id}/holidays`, payload);
  return res.data;
};

export const getWeekdaysInStore = async (id,) => {
  const res = await API.get(`stores/${id}/store-hours`);
  return res.data;
};


export const updateStoreWeekdays = async (id, payload) => {
  const res = await API.put(`stores/${id}/store-hours`, payload);
  return res.data;
};
import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import Avadar_img from "../../../assets/images/Icons/Avadar_img.png";
import { LoadingButton } from "@mui/lab";
import moment from "moment";

export const OpeningAndClosingHourTable = (props) => {

  const {
    handleInputChange,
    state,
    onUpdateWeekdays,
  } = props

  const { hourList, isHourSaveButton, isHourBusy } = state

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },

      validators: {
        openTime: {
          message: "Selected time is not a valid date",
          rule: (value) => {
            return moment(value, "HH:mm:ss").isValid();
          },
        },
        closeTime: {
          message: "The close time should be greater than open time",
          rule: (value) => {
            const { openTime, closeTime } = value;
            return moment(openTime, "HH:mm").isBefore(
              moment(closeTime, "HH:mm")
            );
          },
        },
      },
    })
  );

  const onSubmitWeekDays = () => {
    if (validator.current.allValid()) {
      onUpdateWeekdays();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <Card sx={{ mb: "24px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "20px",
          height: "60px",
          p: "10px 24px",
          bgcolor: "#F2F2F2",
        }}
      >
        <Typography sx={{ width: "100%", fontWeight: 600 }}>
          Week Days
        </Typography>
        <Typography sx={{ width: "100%", fontWeight: 600 }}>
          Open
        </Typography>
        <Typography sx={{ width: "100%", fontWeight: 600 }}>
          Close
        </Typography>
        <Typography sx={{ width: "20%", fontWeight: 600 }}></Typography>
      </Stack>
      {hourList.map((data, index) => (
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "baseline",
            gap: "20px",
            p: "10px 24px",
            borderBottom: "1px solid #e6e6e6",
          }}
        >
          <Typography sx={{ width: "100%", textTransform: "capitalize" }}>
            {data.day_of_week}
          </Typography>

          <InputControl
            name="startAt"
            ampm={false}
            type="timePicker"
            value={moment(data.opening_time, "HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e) => {
              handleInputChange(
                {
                  target: {
                    name: "opening_time",
                    value: moment(e, "HH:mm").format("HH:mm") ?? null,
                  },
                },
                "week",
                index
              );
            }}
          error={validator.current.message(
            `Opening Time ${` `.repeat(index)}`,
            data.opening_time,
            "required|openTime"
          )}
          helperText={validator.current.message(
            `Opening Time ${` `.repeat(index)}`,
            data.opening_time,
            "required|openTime"
          )}
          />
          < InputControl
            name="startAt"
            ampm={false}
            type="timePicker"
            value={moment(data.closing_time, "HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e) => {
              handleInputChange(
                {
                  target: {
                    name: "closing_time",
                    value: moment(e, "HH:mm").format("HH:mm") ?? null,
                  },
                },
                "week",
                index
              );
            }}
          error={validator.current.message(
            `Closing Time ${` `.repeat(index)}`,
            {
              openTime: data.opening_time,
              closeTime: data.closing_time,
            },
            "required|closeTime"
          )}
          helperText={validator.current.message(
            `Closing Time ${` `.repeat(index)}`,
            {
              openTime: data.opening_time,
              closeTime: data.closing_time,
            },
            "required|closeTime"
          )}
          />
          < InputControl
            type="switch"
            checked={data.status ? true : false}
            onChange={(e, v) => {
              handleInputChange(
                {
                  target: {
                    name: "status",
                    value: v ? 1 : 0,
                  },
                },
                "week",
                index
              );
            }}
          />
        </Stack>
      ))}
      <Stack p="24px">
        <LoadingButton
          variant="contained"
          sx={{
            alignSelf: "flex-end",
          }}
          disabled={isHourSaveButton}
          loading={isHourBusy}
          onClick={onSubmitWeekDays}
        >
          Save
        </LoadingButton>
      </Stack>
    </Card>
  );
};

import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import {
  Tab,
  Box,
  Chip,
} from "@mui/material";
import { OnlineOrderingGenaralDetails, OpeningAndClosingHourTable, UberEatsGenaralDetails } from "../components";
// import { OnlineOrderingSettings } from "../components/OnlineOrderingSettings";
import { useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useOnlineOrdering } from "../../OnlineOrdering/hooks/useOnlineOrdering";
import { useUbereats } from "../hook";

export const UberEatsDetails = () => {

  const { id } = useParams();
  const {
    state,
    handleInputChange,
    onUpdateWeekdays,
    handleManageModal,
    handleManageInput,
    onUpdateMangeStatus,
    handlePushMenu,
  } = useUbereats({ storeID: id })

  return (
    <>
      <ContentLayout
        title={state.details?.store_name ?? ""}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              {
                name: "Online Ordering",
                href: "/online-ordering",
              },
              {
                name: state.details?.store_name ?? "",
              }
            ].filter(Boolean)}
          />
        }
        chipType={"other"}
        statusContent={
          <>
            <Chip
              label={state.details?.connection_status ? "Connected" : "Disconnected"}
              variant="outlined"
              size="small"
              deleteIcon={
                state.details?.connection_status ? (
                  <CheckCircleIcon sx={{ color: "#0F875B !important" }} />
                ) : undefined
              }
              onDelete={state.details?.connection_status ? () => { } : undefined}
              sx={{
                border: "none !important",
                background: state.details?.connection_status ? "#E1FBF2" : '#e6e6e6',
              }}
            />
          </>
        }
      >

        <UberEatsGenaralDetails
          state={state}
          handleInputChange={handleInputChange}
          onUpdateWeekdays={onUpdateWeekdays}
          handleManageModal={handleManageModal}
          handleManageInput={handleManageInput}
          onUpdateMangeStatus={onUpdateMangeStatus}
          handlePushMenu={handlePushMenu}
        />
      </ContentLayout>
    </>
  );
};
